import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import HomeLoad from './HomeLoad';
import { Provider } from 'react-redux'
import store from './redux/store.js'
import Constants from './components/constants';
const Root = React.lazy(() =>
  import("./Root")
);

let css_getter = () => {


  if (process.env.REACT_APP_THEME == "WALIYA")
    return `walia.css?v=${Constants.CONFIG.VERSION}`
  else if (process.env.REACT_APP_THEME == "MULA")
    return `mula.css?v=${Constants.CONFIG.VERSION}`
  else if (process.env.REACT_APP_THEME == "QWICKBET")
    return `qwickbetting.css?v=${Constants.CONFIG.VERSION}`
  else if (process.env.REACT_APP_THEME == "HIGHLIGHT")
    return `highlight.css?v=${Constants.CONFIG.VERSION}`
  else if (process.env.REACT_APP_THEME == "VEGAS")
    return `vegas.css?v=${Constants.CONFIG.VERSION}`
  else if (process.env.REACT_APP_THEME == "ABYSSINIA")
    return `abyssinia.css?v=${Constants.CONFIG.VERSION}`
  else if (process.env.REACT_APP_THEME == "SMART")
    return `smartbet.css?v=${Constants.CONFIG.VERSION}`
  else if (process.env.REACT_APP_THEME == "SAMSONN")
    return `samsonn.css?v=${Constants.CONFIG.VERSION}`

  else if (process.env.REACT_APP_THEME == "SCORE")
    return `scorebet.css?v=${Constants.CONFIG.VERSION}`
  
  else if (process.env.REACT_APP_THEME == "GISILLABET")
    return `gisilla.css?v=${Constants.CONFIG.VERSION}`

  else if (process.env.REACT_APP_THEME == "AHUNBET")
    return `ahunbet.css?v=${Constants.CONFIG.VERSION}`
  
  else if (process.env.REACT_APP_THEME == "MARATHON")
    return `marathon.css?v=${Constants.CONFIG.VERSION}`
  
  else if (process.env.REACT_APP_THEME == "KWICKBET")
    return `kwickbet.css?v=${Constants.CONFIG.VERSION}`
  
  else if (process.env.REACT_APP_THEME == "IKOBET")
    return `ikobet.css?v=${Constants.CONFIG.VERSION}`
  
  else if (process.env.REACT_APP_THEME == "ETHIOBET")
    return `ethiobet.css?v=${Constants.CONFIG.VERSION}`

  else if (process.env.REACT_APP_THEME == "POWERBET")
    return `powerbet.css?v=${Constants.CONFIG.VERSION}`
  else if (process.env.REACT_APP_THEME == "STARBET")
    return `starbet.css?v=${Constants.CONFIG.VERSION}`

  else if (process.env.REACT_APP_THEME == "ICONBET")
      return `iconbet.css?v=${Constants.CONFIG.VERSION}`
    
  else if (process.env.REACT_APP_THEME == "AWARDBET")
      return `awardbet.css?v=${Constants.CONFIG.VERSION}`

  else if (process.env.REACT_APP_THEME == "UNIQUEBET")
      return `uniquebet.css?v=${Constants.CONFIG.VERSION}`
    
  else if( process.env.REACT_APP_THEME == "TOPBET")
    return `topbet.css?v=${Constants.CONFIG.VERSION}`
  
  else if( process.env.REACT_APP_THEME == "BET24")
    return `bet24.css?v=${Constants.CONFIG.VERSION}`
  else if( process.env.REACT_APP_THEME == "FANOS")
    return `fanos.css?v=${Constants.CONFIG.VERSION}`

  else if ( process.env.REACT_APP_THEME == "UNOBET")
    return `unobet.css?v=${Constants.CONFIG.VERSION}`
  }

ReactDOM.render(
  <Provider store={store} >
    <React.Suspense fallback={<HomeLoad />}>
      <div>
        <link rel="stylesheet" type="text/css" href={`/assets/css/${css_getter()}`} />

      </div>
      <Root />
    </React.Suspense>
  </Provider>
  ,
  document.getElementById("root")
);


//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
